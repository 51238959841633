// esta es la pagina de gracias de contacto
import React from "react"
import Layout from "../../components/layout"
import Hero from "../../components/hero"
import { Helmet } from "react-helmet"
import Img from "../../assets/images/bgHeroBusesUrbanos.jpg"
import { BusParaPersonalDeLima } from "../../data/buses"
import BlockParaPersonalDeLima from "../../components/buses/blockTpBusesDesc"
export default function BusesParaPersonalDeLima() {
  return (
    <>
      <Helmet>
        <title>Buses para personal de mina</title>
        <meta
          name="description"
          content="Buses para personal de mina de Intramet"
        ></meta>
      </Helmet>
      <Layout>
        <Hero img={Img} title={BusParaPersonalDeLima.title}></Hero>
        <BlockParaPersonalDeLima
          data={BusParaPersonalDeLima}
        ></BlockParaPersonalDeLima>
      </Layout>
    </>
  )
}
